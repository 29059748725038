import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import useFetch from '../../hooks/useFetch';
import { AppWidgetSummary } from '../../sections/@dashboard/app';
import formatNumberAsCurrency from '../../utils/formatNumberAsCurrency';
import formatNumberAsPercentage from '../../utils/formatNumberAsPercentage';
import AppWidgetSummarySkeleton from '../AppWidgetSummarySkeleton';

const DashboardMetrics = ({ dateFrom, dateTo }) => {
  const { data, loading } = useFetch({
    path: '/dashboard/metrics',
    historical: true,
    dateFrom,
    dateTo,
  });

  return loading ? (
    <LoadingState />
  ) : (
    <>
      <Grid item xs={12} sm={6} md={2.4}>
        <AppWidgetSummary
          title="ORDERS"
          total={data.orders}
          icon={'ant-design:shopping-filled'}
          historical={data.historical.orders}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={2.4}>
        <AppWidgetSummary
          title="REVENUE"
          total={formatNumberAsCurrency(data.revenue)}
          color="warning"
          icon={'ant-design:caret-up-filled'}
          historical={data.historical.revenue}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={2.4}>
        <AppWidgetSummary
          title="TOTAL COST"
          total={formatNumberAsCurrency(data.totalCost)}
          color="error"
          icon={'ant-design:caret-down-filled'}
          historical={data.historical.totalCost}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={2.4}>
        <AppWidgetSummary
          title="NET PROFIT"
          total={formatNumberAsCurrency(data.netProfit)}
          color="info"
          icon={'ant-design:pie-chart-filled'}
          historical={data.historical.netProfit}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={2.4}>
        <AppWidgetSummary
          title="NET MARGIN"
          total={formatNumberAsPercentage(data.netMargin)}
          color="success"
          icon={'ant-design:eye-filled'}
          historical={data.historical.netMargin}
        />
      </Grid>
    </>
  );
};

const LoadingState = () => (
  <>
    <Grid item xs={12} sm={6} md={2.4}>
      <AppWidgetSummarySkeleton />
    </Grid>

    <Grid item xs={12} sm={6} md={2.4}>
      <AppWidgetSummarySkeleton />
    </Grid>

    <Grid item xs={12} sm={6} md={2.4}>
      <AppWidgetSummarySkeleton />
    </Grid>

    <Grid item xs={12} sm={6} md={2.4}>
      <AppWidgetSummarySkeleton />
    </Grid>

    <Grid item xs={12} sm={12} md={2.4}>
      <AppWidgetSummarySkeleton />
    </Grid>
  </>
);

DashboardMetrics.propTypes = {
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
};

export default DashboardMetrics;
