import { addDays, addMonths, addWeeks, addYears, endOfMonth, endOfYear, startOfMonth, startOfYear } from 'date-fns';

export const getDefaultRanges = (date = new Date()) => [
  {
    label: 'Today',
    startDate: date,
    endDate: date,
  },
  {
    label: 'Yesterday',
    startDate: addDays(date, -1),
    endDate: addDays(date, -1),
  },
  {
    label: 'Last 7 Days',
    startDate: addWeeks(date, -1),
    endDate: addDays(date, -1),
  },
  {
    label: 'Last 14 Days',
    startDate: addWeeks(date, -2),
    endDate: addDays(date, -1),
  },
  {
    label: 'Last 30 Days',
    startDate: addDays(date, -30),
    endDate: addDays(date, -1),
  },
  {
    label: 'Last Month',
    startDate: startOfMonth(addMonths(date, -1)),
    endDate: endOfMonth(addMonths(date, -1)),
  },
  {
    label: 'This Year',
    startDate: startOfYear(date),
    endDate: addDays(date, -1),
  },
  {
    label: 'Last Year',
    startDate: startOfYear(addYears(date, -1)),
    endDate: endOfYear(addYears(date, -1)),
  },
];
