import { TableCell, Tooltip, IconButton, TableRow } from '@mui/material';

import Label from '../components/Label';
import Iconify from '../components/Iconify';

import compareHistoricalDifference from './compareHistoricalDifference';
import formatNumberAsCurrency from './formatNumberAsCurrency';
import formatNumberAsPercentage from './formatNumberAsPercentage';

const getTableRow = (product, isTotals = false) => {
  return (
    <TableRow hover key={product.productId + product.title} tabIndex={-1} selected={isTotals} aria-checked={false}>
      {!isTotals && (
        <TableCell
          align="left"
          width="15%"
          sx={{ ...(isTotals ? { fontWeight: 'bold' } : {}), position: 'sticky', left: 0 }}
        >
          <span style={{ marginRight: '5px' }}>{product.productTitle}</span>
          {product.marketing === 0 && (
            <Tooltip title="Marketing data unaviable for product!">
              <IconButton color="error" sx={{ py: 0, px: 0, mr: '3px' }}>
                <Iconify icon="eva:google-fill" sx={{ fontSize: '18px' }} />
              </IconButton>
            </Tooltip>
          )}
          {!product.historical && (
            <Tooltip title="Compare data unavailable for product!">
              <IconButton color="warning" sx={{ py: 0, px: 0 }}>
                <Iconify icon="eva:alert-circle-fill" sx={{ fontSize: '18px' }} />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      )}

      {isTotals && (
        <TableCell align="left" width="15%" sx={{ position: 'sticky', left: 0, zIndex: 2 }}>
          <span style={{ marginRight: '5px' }}>
            <strong>Summary</strong>
          </span>
        </TableCell>
      )}

      <TableCell align="center">
        <p>
          <Label color={product.profit >= 0 ? 'success' : 'error'}>{formatNumberAsCurrency(product.profit)}</Label>
        </p>
        {product.historical && compareHistoricalDifference(product.historical.profit)}
      </TableCell>
      <TableCell align="center">
        <p>{formatNumberAsPercentage(product.profitMargin)}</p>
        {product.historical && compareHistoricalDifference(product.historical.profitMargin)}
      </TableCell>

      <TableCell align="center">
        <p>{formatNumberAsCurrency(product.grossProfit)}</p>
        {product.historical && compareHistoricalDifference(product.historical.grossProfit)}
      </TableCell>

      <TableCell align="center">
        <p>{formatNumberAsCurrency(product.totalSales)}</p>
        {product.historical && compareHistoricalDifference(product.historical.totalSales)}
      </TableCell>

      <TableCell align="center">
        <p>{formatNumberAsCurrency(product.cogs)}</p>
        {product.historical && compareHistoricalDifference(product.historical.cogs)}
      </TableCell>

      <TableCell align="center">
        <p>{formatNumberAsCurrency(product.marketing)}</p>
        {product.historical && compareHistoricalDifference(product.historical.marketing)}
      </TableCell>

      {!isTotals ? (
      <TableCell align="center">
        {product.price ? (
          <>
            <p>{formatNumberAsCurrency(product.price)}</p>
            {product.historical && compareHistoricalDifference(product.historical.price)}
          </>
        ) : (
          <Tooltip title="Missing product price from report">
            <IconButton color="info" sx={{ py: 0, px: 0 }}>
              <Iconify icon="eva:bulb-fill" sx={{ fontSize: '18px' }} />
            </IconButton>
          </Tooltip>
        )
        }
        
      </TableCell>
      ) : (
        <TableCell>{' '}</TableCell>
      )}

      <TableCell align="center">
        <p>{product.netQuantity}</p>
        {product.historical && compareHistoricalDifference(product.historical.netQuantity)}
      </TableCell>

      <TableCell align="center">
        <p>{formatNumberAsPercentage(product.roas)}</p>
        {product.historical && compareHistoricalDifference(product.historical.roas)}
      </TableCell>

      <TableCell align="center">
        <p>{formatNumberAsPercentage(product.cogsMargin)}</p>
        {product.historical && compareHistoricalDifference(product.historical.cogsMargin)}
      </TableCell>

      <TableCell align="center">
        <p>{formatNumberAsPercentage(product.acos)}</p>
        {product.historical && compareHistoricalDifference(product.historical.acos)}
      </TableCell>

      <TableCell align="center">
        <p>{formatNumberAsCurrency(product.grossSales)}</p>
        {product.historical && compareHistoricalDifference(product.historical.grossSales)}
      </TableCell>

      <TableCell align="center">
        <p>{formatNumberAsCurrency(product.discounts)}</p>
        {product.historical && compareHistoricalDifference(product.historical.discounts)}
      </TableCell>

      <TableCell align="center">
        <p>{formatNumberAsCurrency(product.returns)}</p>
        {product.historical && compareHistoricalDifference(product.historical.returns)}
      </TableCell>

      <TableCell align="center">
        <p>{formatNumberAsCurrency(product.tax)}</p>
        {product.historical && compareHistoricalDifference(product.historical.tax)}
      </TableCell>

      <TableCell align="center">
        <p>{formatNumberAsPercentage(product.roi)}</p>
        {product.historical && compareHistoricalDifference(product.historical.roi)}
      </TableCell>

      <TableCell align="center">
        {!isTotals && <Label color={product.priority === 'YES' ? 'success' : 'error'}>{product.priority}</Label>}
      </TableCell>
    </TableRow>
  );
};

const formatSalesAndProfitReport = (products, totals) => {
  return (
    <>
      {getTableRow(totals, true)}
      {products.map((product) => getTableRow(product))}
    </>
  );
};

export default formatSalesAndProfitReport;
