import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';

import Page from '../components/Page';

import { ResetPasswordForm } from '../sections/auth/reset';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function ResetPassword() {
  return (
    <Page title="Reset Password">
      <RootStyle>
        <Container>
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              Reset your password.
            </Typography>
            <Typography sx={{ color: 'text.secondary', mb: 5 }}>
              Fill out the necessary fields and you are ready to go!
            </Typography>
            <ResetPasswordForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
