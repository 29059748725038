// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import { AuthProvider } from './hooks/auth';
import { StoreProvider } from './hooks/store';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <StoreProvider>
          <ScrollToTop />
          <BaseOptionChartStyle />
          <Router />
        </StoreProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}
