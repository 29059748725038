import moment from 'moment';
import { getDefaultRanges } from './getDefaultDateRanges';

const getDateRangeLabel = ({ dateFrom, dateTo }) => {
  const defaultDateRanges = getDefaultRanges();

  const dateRange = defaultDateRanges.find((defaultDateRange) => {
    const dateFromDefault = moment(defaultDateRange.startDate).format('YYYY-MM-DD');
    const dateToDefault = moment(defaultDateRange.endDate).format('YYYY-MM-DD');

    if (dateFrom === dateFromDefault && dateTo === dateToDefault) {
      return true;
    }

    return false;
  });

  if (dateRange) {
    return dateRange.label;
  }

  return `${dateFrom} - ${dateTo}`;
};

export default getDateRangeLabel;
