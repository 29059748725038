import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { Card, Box, Container, Typography, Button } from '@mui/material';

import useResponsive from '../hooks/useResponsive';

import Page from '../components/Page';
import Logo from '../components/Logo';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function RegisterConfirmation() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');
  const navigate = useNavigate();

  return (
    <Page title="Confirmation">
      <RootStyle>
        <HeaderStyle>
          <Logo />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Thank you for creating an account with us!
            </Typography>
            <img alt="register" src="/static/illustrations/illustration_register.png" />

            <Box sx={{ px: 5, mt: 10, mb: 5 }}>
              <Button onClick={() => navigate('/login')} fullWidth size="large" type="submit" variant="contained">
                Continue to log in
              </Button>
            </Box>
          </SectionStyle>
        )}

        <Container>
          <ContentStyle>
            {!smUp && (
              <>
                <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                  Thank you for creating an account with us!
                </Typography>
                <img alt="register" src="/static/illustrations/illustration_register.png" />

                <Box sx={{ px: 5, mt: 10, mb: 5 }}>
                  <Button onClick={() => navigate('/login')} fullWidth size="large" type="submit" variant="contained">
                    Continue to log in
                  </Button>
                </Box>
              </>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
