import { filter } from 'lodash';
import { useMemo, useState } from 'react';

import {
  Box,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  keyframes,
} from '@mui/material';

import { DateRangePicker } from 'mui-daterange-picker';
import moment from 'moment/moment';

import { fadeIn } from 'react-animations';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, SearchToolbar } from '../sections/@dashboard/user';

import useFetch from '../hooks/useFetch';

import { getDefaultRanges } from '../utils/getDefaultDateRanges';
import getDateRangeLabel from '../utils/getDateRangeLabel';

import formatSalesAndProfitReport from '../utils/formatSalesAndProfitReport';
import SalesAndProfitByProductSkeleton from '../components/SalesAndProfitByProductSkeleton';

const TABLE_HEAD = [
  { id: 'productTitle', label: 'Product title', align: 'left', sticky: true },
  { id: 'profit', label: 'REAL PROFIT', align: 'center' },
  { id: 'profitMargin', label: 'Profit margin', align: 'center' },
  { id: 'grossProfit', label: 'Gross profit', align: 'center' },
  { id: 'totalSales', label: 'Total sales', align: 'center' },
  { id: 'cogs', label: 'COGS', align: 'center' },
  { id: 'marketing', label: 'Marketing', align: 'center' },
  { id: 'netQuantity', label: 'Quantity', align: 'center' },
  { id: 'roas', label: 'ROAS', align: 'center' },
  { id: 'cogsMargin', label: 'COGS margin', align: 'center' },
  { id: 'acos', label: 'ACOS', align: 'center' },
  { id: 'grossSales', label: 'Gross sales', align: 'center' },
  { id: 'discounts', label: 'Discounts', align: 'center' },
  { id: 'returns', label: 'Returns', align: 'center' },
  { id: 'tax', label: 'Tax', align: 'center' },
  { id: 'roi', label: 'ROI', align: 'center' },
  { id: 'priority', label: 'Priority', align: 'center' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_product) => _product.productTitle.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function SalesAndProfitByProduct() {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('profit');
  const [filterName, setFilterName] = useState('');

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const [open, setOpen] = useState(false);

  const toggle = () => setOpen(!open);

  const [dateRange, setDateRange] = useState({
    dateFrom: moment().add(-7, 'days').format('YYYY-MM-DD'),
    dateTo: moment().add(-1, 'days').format('YYYY-MM-DD'),
  });

  const { data, loading } = useFetch({
    path: '/report/product-profitability-tracker',
    historical: true,
    dateFrom: dateRange.dateFrom,
    dateTo: dateRange.dateTo,
  });

  const filteredProducts = useMemo(() => {
    if (!loading) {
      return applySortFilter(data.products, getComparator(order, orderBy), filterName);
    }

    return [];
  }, [loading, filterName, data, order, orderBy]);

  const emptyRows = 0;

  const isProductNotFound = filteredProducts.length === 0;

  const handleChangeRange = (range) => {
    const dateFrom = moment(range.startDate).format('YYYY-MM-DD');
    const dateTo = moment(range.endDate).format('YYYY-MM-DD');

    setDateRange({ dateFrom, dateTo });
    setOpen(false);
  };

  const dateRangeLabel = getDateRangeLabel({ dateFrom: dateRange.dateFrom, dateTo: dateRange.dateTo });

  const fadeInAnimation = keyframes`${fadeIn}`;

  return (
    <Page title="Product Profitability Tracker">
      <Stack direction="column" alignItems="left" justifyContent="space-between" mb={5}>
        <Box sx={{ mt: 0 }}>
          <Button
            size="large"
            variant="contained"
            onClick={() => setOpen(true)}
            startIcon={<Iconify icon="eva:calendar-fill" />}
          >
            {dateRangeLabel}
          </Button>
          <DateRangePicker
            open={open}
            toggle={toggle}
            onChange={(range) => handleChangeRange(range)}
            definedRanges={getDefaultRanges()}
            wrapperClassName="wrapper"
          />
        </Box>
      </Stack>
      {loading && <SalesAndProfitByProductSkeleton />}
      {!loading && (
        <Card
          sx={{
            animation: `1s ${fadeInAnimation}`,
          }}
        >
          <SearchToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            title="Product Profitability Tracker"
          />
          <TableContainer defaultComponent="Paper" sx={{ maxHeight: 'calc(100vh - 385px)' }}>
            <Table
              stickyHeader
              sx={{
                '& .MuiTableRow-root:not(:first-of-type)': {
                  '& .MuiTableCell-root': {
                    backgroundColor: '#fff',
                  },
                },
                '& .MuiTableRow-root:hover': {
                  '& .MuiTableCell-root': {
                    backgroundColor: '#fafafa',
                  },
                },
                '& .Mui-selected': {
                  '& .MuiTableCell-root': {
                    backgroundColor: '#e3f2fd',
                  },
                },
                '& .Mui-selected:hover': {
                  '& .MuiTableCell-root': {
                    backgroundColor: '#bbdefb',
                  },
                },
              }}
            >
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={data.products.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {formatSalesAndProfitReport(filteredProducts, data.totals)}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={13} />
                  </TableRow>
                )}
              </TableBody>
              {isProductNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={TABLE_HEAD.length} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[{ value: filteredProducts.length, label: 'Show all' }]}
            component="div"
            count={filteredProducts.length}
            rowsPerPage={filteredProducts.length}
            page={0}
          />
        </Card>
      )}
    </Page>
  );
}
