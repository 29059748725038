import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuthContext } from './auth';
import { useHostContext } from './host';

const useFetch = ({ path, historical, dateFrom, dateTo }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [shouldRefetch, refetch] = useState({});

  const { accessToken } = useAuthContext();
  const { HOST } = useHostContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const params = {
          historical,
          dateFrom,
          dateTo,
        };

        const { data: response } = await axios.get(`${HOST}${path}`, {
          params,
          headers: {
            'x-access-token': accessToken,
          },
        });

        setData(response);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };

    fetchData();
  }, [dateFrom, dateTo, shouldRefetch]);

  return {
    data,
    loading,
    refetch,
  };
};

export default useFetch;
