import axios from 'axios';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useStoreContext } from '../hooks/store';

const formatSaleReportsV2 = (saleReports) =>
  saleReports.reduce(
    (accummulator, report) => {
      if (report.period === 'YEAR') {
        return {
          ...accummulator,
          yearlyReports: [
            ...accummulator.yearlyReports,
            {
              id: report.id,
              name: moment(report.dateFrom).format('YYYY'),
              dateFrom: report.dateFrom,
              dateTo: report.dateTo,
            },
          ],
        };
      }

      if (report.period === 'MONTH') {
        const yearOfReport = moment(report.dateFrom).format('YYYY');
        const reportForYear = accummulator.monthlyReports.find((monthlyReport) => monthlyReport.year === yearOfReport);

        if (!reportForYear) {
          return {
            ...accummulator,
            monthlyReports: [
              ...accummulator.monthlyReports,
              {
                year: yearOfReport,
                reports: [
                  {
                    id: report.id,
                    name: moment(report.dateFrom).format('MMMM'),
                    dateFrom: report.dateFrom,
                    dateTo: report.dateTo,
                  },
                ],
              },
            ],
          };
        }

        if (reportForYear) {
          const updatedMonthlyReports = accummulator.monthlyReports.reduce((acc, nextMonthlyReport) => {
            if (nextMonthlyReport.year === yearOfReport) {
              return [
                ...acc,
                {
                  ...nextMonthlyReport,
                  reports: [
                    ...nextMonthlyReport.reports,
                    {
                      id: report.id,
                      name: moment(report.dateFrom).format('MMMM'),
                      dateFrom: report.dateFrom,
                      dateTo: report.dateTo,
                    },
                  ],
                },
              ];
            }

            return [...acc, nextMonthlyReport];
          }, []);

          return {
            ...accummulator,
            monthlyReports: [...updatedMonthlyReports],
          };
        }
      }

      if (report.period === 'WEEK') {
        const yearOfReport = moment(report.dateFrom).format('YYYY');
        const monthOfReport = moment(report.dateFrom).format('MMMM');
        const reportForMonth = accummulator.weeklyReports.find(
          (weeklyReport) => weeklyReport.month === monthOfReport && weeklyReport.year === yearOfReport
        );

        if (!reportForMonth) {
          return {
            ...accummulator,
            weeklyReports: [
              ...accummulator.weeklyReports,
              {
                year: yearOfReport,
                month: monthOfReport,
                reports: [
                  {
                    id: report.id,
                    name: `${moment(report.dateFrom).format('MMMM DD')} - ${moment(report.dateTo).format('MMMM DD')}`,
                    dateFrom: report.dateFrom,
                    dateTo: report.dateTo,
                  },
                ],
              },
            ],
          };
        }

        if (reportForMonth) {
          const updatedWeeklyReports = accummulator.weeklyReports.reduce((acc, nextWeeklyReport) => {
            if (nextWeeklyReport.month === monthOfReport && nextWeeklyReport.year === yearOfReport) {
              return [
                ...acc,
                {
                  ...nextWeeklyReport,
                  reports: [
                    ...nextWeeklyReport.reports,
                    {
                      id: report.id,
                      name: `${moment(report.dateFrom).format('MMMM DD')} - ${moment(report.dateTo).format('MMMM DD')}`,
                      dateFrom: report.dateFrom,
                      dateTo: report.dateTo,
                    },
                  ],
                },
              ];
            }

            return [...acc, nextWeeklyReport];
          }, []);

          return {
            ...accummulator,
            weeklyReports: [...updatedWeeklyReports],
          };
        }
      }

      return accummulator;
    },
    {
      yearlyReports: [],
      monthlyReports: [],
      weeklyReports: [],
    }
  );

const reportSorter = (duration) => {
  if (duration.length === 0) {
    return duration;
  }

  const sortedDuration = duration.sort((a, b) => {
    if (
      moment({
        day: 1,
        year: b.year,
        month: b.month ? Number(moment().month(b.month).format('M')) - 1 : 1,
      }) <
      moment({
        day: 1,
        year: a.year,
        month: a.month ? Number(moment().month(a.month).format('M')) - 1 : 1,
      })
    ) {
      return 1;
    }

    return -1;
  });

  const durationWithSortedReports = sortedDuration.map((dur) => ({
    ...dur,
    ...(dur.reports
      ? {
          reports: dur?.reports?.sort(
            (a, b) => moment(a.dateFrom).format('YYYYMMDD') - moment(b.dateFrom).format('YYYYMMDD')
          ),
        }
      : undefined),
  }));

  return durationWithSortedReports;
};

const useProductProfitabilityData = ({ dateFrom, dateTo, HOST, accessToken }) => {
  const { storeId } = useStoreContext();
  const [saleReports, setSaleReports] = useState({});
  const [activeSaleReport, setActiveSaleReport] = useState({});
  const [productProfitability, setProductProfitability] = useState([]);
  const [loading, setLoading] = useState(true);
  const [shouldRefetch, refetch] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const { data: saleReportsData } = await axios.get(`${HOST}/report/store/${storeId}/report`, {
          params: { storeId },
          headers: {
            'x-access-token': accessToken,
          },
        });

        if (saleReportsData.length) {
          const formattedSaleReports = formatSaleReportsV2(saleReportsData);

          const sortedAndFormattedSaleReports = {
            weeklyReports: reportSorter(formattedSaleReports.weeklyReports),
            monthlyReports: reportSorter(formattedSaleReports.monthlyReports),
            yearlyReports: reportSorter(formattedSaleReports.yearlyReports),
          };

          const sortedSaleReportsData = saleReportsData.sort(
            (a, b) => moment(a.dateFrom).format('YYYYMMDD') - moment(b.dateFrom).format('YYYYMMDD')
          );

          let saleReport = {};

          const isReportMissingFromAndToDates = !dateFrom && !dateTo;

          if (isReportMissingFromAndToDates) {
            saleReport = sortedSaleReportsData[sortedSaleReportsData.length - 1];
          } else {
            saleReport = sortedSaleReportsData.find(
              (report) => report.dateFrom === dateFrom && report.dateTo === dateTo
            );
          }

          if (!saleReport) {
            saleReport = sortedSaleReportsData[sortedSaleReportsData.length - 1];
          }

          const { data: productProfitabilityData } = await axios.get(
            `${HOST}/report/${saleReport.id}/sales-by-product`,
            {
              headers: {
                'x-access-token': accessToken,
              },
            }
          );

          setSaleReports(sortedAndFormattedSaleReports);
          setActiveSaleReport(saleReport);
          setProductProfitability(productProfitabilityData);
        } else {
          setSaleReports({});
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetch, dateFrom, dateTo, storeId]);

  return {
    saleReports,
    activeSaleReport,
    productProfitability,
    loading,
    refetch,
  };
};

export default useProductProfitabilityData;
