import { Grid, keyframes } from '@mui/material';
import { fadeIn } from 'react-animations';
import { useTheme } from '@mui/material/styles';
import useFetch from '../../hooks/useFetch';

import { CostBreakdown } from '../../sections/@dashboard/app';
import compareHistoricalDifference from '../../utils/compareHistoricalDifference';
import CostBreakdownSkeleton from '../CostBreakdownSkeleton';
import Label from '../Label';

const DashboardCostBreakdown = ({ dateFrom, dateTo }) => {
  const theme = useTheme();

  const { data, loading } = useFetch({
    path: '/dashboard/cost-breakdown',
    historical: true,
    dateFrom,
    dateTo,
  });
  console.log('🚀 ~ file: DashboardCostBreakdown.js:20 ~ DashboardCostBreakdown ~ data', data);

  const fadeInAnimation = keyframes`${fadeIn}`;

  const getLabelColor = (value) => {
    if (value === 0) {
      return 'info';
    }

    if (value > 0) {
      return 'error';
    }

    if (value < 0) {
      return 'success';
    }
  };

  return loading ? (
    <LoadingState />
  ) : (
    <Grid item xs={12} md={6} lg={6}>
      <CostBreakdown
        sx={{
          animation: `1s ${fadeInAnimation}`,
        }}
        subheader={
          <Label color={getLabelColor(data.historical.total)} sx={{ mb: 1 }}>
            {compareHistoricalDifference(data.historical.total, false, true)}
          </Label>
        }
        title="Cost Breakdown"
        total={data.total}
        chartData={[
          { label: 'COGS', value: data.cogs, historical: data.historical.cogs },
          { label: 'Ad spend', value: data.adSpend, historical: data.historical.adSpend },
          { label: 'Returns', value: Math.abs(data.returns), historical: data.historical.returns },
          { label: 'Shipping', value: data.shipping, historical: data.historical.shipping },
          { label: 'Custom cost', value: Math.abs(data.customCost), historical: data.historical.customCost },
          {
            label: 'Transaction fees',
            value: Math.abs(data.transactionFees),
            historical: data.historical.transactionFees,
          },
        ]}
        chartColors={[
          theme.palette.info.light,
          theme.palette.success.light,
          theme.palette.secondary.light,
          theme.palette.warning.light,
          theme.palette.error.light,
          theme.palette.chart.violet[0],
        ]}
      />
    </Grid>
  );
};

const LoadingState = () => {
  return (
    <Grid item xs={12} md={6} lg={6}>
      <CostBreakdownSkeleton />
    </Grid>
  );
};

export default DashboardCostBreakdown;
