import { Typography } from '@mui/material';
import Iconify from '../components/Iconify';
import formatNumberAsPercentage from './formatNumberAsPercentage';

const getColor = (value, isReverted) => {
  let color;

  if (value === 0) {
    color = '#03a9f4';
  }

  if (value > 0 && isReverted === false) {
    color = '#229A16';
  }

  if (value < 0 && isReverted === false) {
    color = '#ef5350';
  }

  if (value === 0) {
    color = '#03a9f4';
  }

  if (value > 0 && isReverted === true) {
    color = '#ef5350';
  }

  if (value < 0 && isReverted === true) {
    color = '#229A16';
  }

  return color;
};

const getIcon = (value) => {
  let icon;

  if (value === 0) {
    icon = 'eva:activity-fill';
  }

  if (value > 0) {
    icon = 'eva:trending-up-fill';
  }

  if (value < 0) {
    icon = 'eva:trending-down-fill';
  }

  return icon;
};

const compareHistoricalDifference = (pastValue, isDashboard = false, isReverted = false) => {
  if (pastValue === undefined || pastValue === null || pastValue === 'yes' || pastValue === 'no') {
    return null;
  }

  const color = isDashboard ? '' : getColor(pastValue, isReverted);
  const icon = getIcon(pastValue);

  return (
    <div
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        flexWrap: 'no-wrap',
        color,
        gap: '2px',
        textAlign: 'center',
        opacity: 1,
      }}
    >
      <Iconify sx={{ fontSize: '18px' }} icon={icon} />
      <Typography sx={{ fontSize: '12px' }}>{formatNumberAsPercentage(pastValue)}</Typography>
    </div>
  );
};

export default compareHistoricalDifference;
