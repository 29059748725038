import { Navigate, useRoutes } from 'react-router-dom';

// auth
import RequireAuth from './components/RequireAuth';

// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

import Blog from './pages/Blog';
import SalesAndProfitByProduct from './pages/SalesAndProfitByProduct';
import SalesAndProfitByProductV2 from './pages/SalesAndProfitByProductV2';
import UploadSalesReport from './pages/UploadSalesReport';

import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import { useAuthContext } from './hooks/auth';
import RegisterConfirmation from './pages/RegisterConfirmation';
import Team from './pages/Team';
import ResetPassword from './pages/ResetPassword';
import ForgotPassword from './pages/ForgotPassword';
import Stores from './pages/Stores';

export default function Router() {
  const { isUserAuthed } = useAuthContext();

  return useRoutes([
    {
      path: '/dashboard',
      element: (
        <RequireAuth key={isUserAuthed} isUserAuthed={isUserAuthed}>
          <DashboardLayout />
        </RequireAuth>
      ),
      children: [
        { path: 'app', element: <SalesAndProfitByProductV2 /> },
        { path: 'upload-sales-report', element: <UploadSalesReport /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'product-profitability-tracker', element: <SalesAndProfitByProduct /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
      ],
    },
    {
      path: '/team',
      element: (
        <RequireAuth key={isUserAuthed} isUserAuthed={isUserAuthed}>
          <DashboardLayout />
        </RequireAuth>
      ),
      children: [{ path: '', element: <Team /> }],
    },
    {
      path: '/stores',
      element: (
        <RequireAuth key={isUserAuthed} isUserAuthed={isUserAuthed}>
          <DashboardLayout />
        </RequireAuth>
      ),
      children: [{ path: '', element: <Stores /> }],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'confirmation', element: <RegisterConfirmation /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
