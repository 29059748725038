import { filter } from 'lodash';

import {
  Card,
  Container,
  keyframes,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Box,
  Snackbar,
  Alert,
  Slide,
} from '@mui/material';
import { useState, useMemo } from 'react';

import { fadeIn } from 'react-animations';
import CreateStore from '../components/store/CreateStore';
import DeleteStore from '../components/store/DeleteStore';
import EditStore from '../components/store/EditStore';
import Page from '../components/Page';
import useFetch from '../hooks/useFetch';
import { SearchToolbar, UserListHead } from '../sections/@dashboard/user';
import SearchNotFound from '../components/SearchNotFound';
import LoadingSkeleton from '../components/LoadingSkeleton';
import { useAuthContext } from '../hooks/auth';
import { useHostContext } from '../hooks/host';

const TABLE_HEAD = [
  { id: 'name', label: 'Store name', align: 'left' },
  { id: 'url', label: 'Store URL', align: 'left' },
  { id: 'googleCustomerId', label: 'Google Customer ID', align: 'left' },
  { id: 'googleCustomerCurrency', label: 'Google Customer Currency', align: 'left' },
  { id: 'actions', label: 'Actions', align: 'left' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_store) =>
        _store.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _store.url.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Stores() {
  const [filterName, setFilterName] = useState('');
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('startDate');
  const { accessToken } = useAuthContext();
  const { HOST } = useHostContext();

  const [isDeleted, setIsDeleted] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [isCreated, setIsCreated] = useState(false);

  const {
    data: stores,
    loading: storesLoading,
    refetch: refetchStores,
  } = useFetch({
    path: '/store',
  });

  const filteredStores = useMemo(() => {
    if (!storesLoading) {
      return applySortFilter(stores, getComparator(order, orderBy), filterName);
    }

    return [];
  }, [storesLoading, filterName, stores, order, orderBy]);

  const fadeInAnimation = keyframes`${fadeIn}`;

  const emptyRows = 0;

  const isStoreNotFound = filteredStores.length === 0;

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const formatGoogleCustomerId = (string) => `${string.slice(0, 3)}-${string.slice(3, 6)}-${string.slice(6)}`;

  return (
    <Page title="Manage Stores">
      <Container maxWidth="xl">
        <Box sx={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'space-between' }}>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Manage Stores
          </Typography>
          <CreateStore refetch={refetchStores} setIsCreated={setIsCreated} />
        </Box>
        <Snackbar
          open={isDeleted}
          TransitionComponent={(props) => <Slide {...props} direction="down" />}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={5000}
          onClose={() => setIsDeleted(false)}
          onC
        >
          <Alert onClose={() => {}} severity="success" sx={{ width: '100%' }}>
            Store was deleted. Nice! 😊
          </Alert>
        </Snackbar>
        <Snackbar
          open={isCreated}
          TransitionComponent={(props) => <Slide {...props} direction="down" />}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={5000}
          onClose={() => setIsCreated(false)}
        >
          <Alert onClose={() => {}} severity="success" sx={{ width: '100%' }}>
            Store was created. Nice! 😊
          </Alert>
        </Snackbar>
        <Snackbar
          open={isEdited}
          TransitionComponent={(props) => <Slide {...props} direction="down" />}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={5000}
          onClose={() => setIsEdited(false)}
        >
          <Alert onClose={() => {}} severity="success" sx={{ width: '100%' }}>
            Store was edited. Nice! 😊
          </Alert>
        </Snackbar>

        {storesLoading && <LoadingSkeleton />}
        {!storesLoading && (
          <Card
            sx={{
              animation: `1s ${fadeInAnimation}`,
            }}
          >
            <SearchToolbar filterName={filterName} onFilterName={handleFilterByName} title="" />
            <TableContainer sx={{ maxHeight: 'calc(100vh - 350px)' }}>
              <Table stickyHeader>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={stores.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredStores.map((store, storeIndex) => (
                    <TableRow key={storeIndex}>
                      <TableCell align="left">{store.name}</TableCell>
                      <TableCell align="left">{store.url}</TableCell>
                      <TableCell align="left">{formatGoogleCustomerId(store.googleCustomerId)}</TableCell>
                      <TableCell align="left">{store.googleCustomerCurrency}</TableCell>
                      <TableCell width="10%" align="left">
                        <Box sx={{ display: 'inline-flex', gap: 1, flexWrap: 'nowrap' }}>
                          <EditStore store={store} refetch={refetchStores} setIsEdited={setIsEdited} />
                          <DeleteStore
                            name={store.name}
                            id={store.id}
                            refetch={refetchStores}
                            setIsDeleted={setIsDeleted}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={13} />
                    </TableRow>
                  )}
                </TableBody>
                {isStoreNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={13} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[{ value: filteredStores.length, label: 'Show all' }]}
              component="div"
              onPageChange={() => {}}
              count={filteredStores.length}
              rowsPerPage={filteredStores.length}
              page={0}
            />
          </Card>
        )}
      </Container>
    </Page>
  );
}
