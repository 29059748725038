/* eslint-disable */
import { useEffect, useState } from 'react';
import { Container, Input, Typography, Box, Button, FormLabel, Alert } from '@mui/material';

import Page from '../components/Page';
import axios from 'axios';
import Iconify from '../components/Iconify';
import { useAuthContext } from '../hooks/auth';
import { useHostContext } from '../hooks/host';
import { useStoreContext } from 'src/hooks/store';

export default function UploadSalesReport() {
  const { accessToken } = useAuthContext();
  const { HOST } = useHostContext();
  const { storeId, getAllStores } = useStoreContext();

  const [storeName, setStoreName] = useState('');

  const [file, setFile] = useState();
  const [error, setError] = useState({});
  const [success, setSuccess] = useState({});

  const fileReader = new FileReader();

  useEffect(() => {
    const fetchStores = async () => {
      const stores = await getAllStores();
      const store = stores.find((store) => store.id === Number(storeId));

      if (store.name) {
        setStoreName(store.name);
      }
    };

    fetchStores();
  }, [storeId]);

  const handleChange = (event) => {
    setError({});
    setFile(event.target.files[0]);
  };

  const convertCsvToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf('\n')).split(',');
    const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');

    const array = csvRows.map((i) => {
      const values = i.split(',');

      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});

      return obj;
    });

    return array;
  };

  const validateReportData = (report) => {
    const mappedReport = report.map((sale) => ({
      product_id: sale.product_id,
      product_title: sale.product_title,
      variant_sku: sale.variant_sku,
      product_price: sale.product_price,
      net_quantity: sale.net_quantity,
      gross_sales: sale.gross_sales,
      discounts: sale.discounts,
      returns: sale.returns,
      taxes: sale.taxes,
      total_sales: sale.total_sales,
      total_cost: sale.total_cost,
      gross_profit: sale.gross_profit,
    }));

    // TO-DO:
    // have a list of all products for [wrapango - storeId = 1]
    // check if row does not have a product id
    // use the product name to match it to a product from the list
    // map the product ID and return the containing relevant product ID

    const validSaleEntries = mappedReport.filter((row) => {
      return (
        Boolean(row.product_id) &&
        Boolean(row.product_title) &&
        Boolean(row.variant_sku) &&
        Boolean(row.product_price) &&
        Boolean(row.net_quantity) &&
        Boolean(row.gross_sales) &&
        Boolean(row.discounts) &&
        Boolean(row.returns) &&
        Boolean(row.taxes) &&
        Boolean(row.total_sales) &&
        Boolean(row.total_cost) &&
        Boolean(row.gross_profit)
      );
    });

    return validSaleEntries;
  };

  const uploadSalesByProductVariant = async (salesByProductVariant) => {
    console.log('🚀 ~ uploadSalesByProductVariant ~ salesByProductVariant:', salesByProductVariant);
    setError({});
    setSuccess({});
    const [_, dateFrom, dateTo] = file.name.replace('.csv', '').split('_');

    if (!dateFrom || !dateTo) {
      setError({
        error: true,
        message: `
          Please use a format like 
          "sales_2024-01-01_2024-01-31" for the file name, where 
          the dates represent the start and end dates in the 
          year-month-day format.
        `,
      });

      return;
    }

    const validSalesByProductVariant = validateReportData(salesByProductVariant);
    console.log('🚀 ~ uploadSalesByProductVariant ~ validSalesByProductVariant:', validSalesByProductVariant);

    const payload = {
      dateFrom,
      dateTo,
      salesByProductVariant: validSalesByProductVariant,
    };
    console.log('🚀 ~ uploadSalesByProductVariant ~ payload:', payload);

    try {
      await axios({
        method: 'POST',
        url: `${HOST}/report/store/${storeId}/sales-by-product`,
        data: payload,
        headers: {
          'x-access-token': accessToken,
        },
      });

      setSuccess({ success: true, message: 'File uploaded successfully!' });
      setError({});
    } catch (err) {
      console.log(err);
      setError({ error: true, message: 'Failed to upload sales report.' });
    }
  };

  const handleUpload = (event) => {
    setError({});

    event.preventDefault();

    if (file) {
      fileReader.onload = async function (event) {
        const text = event.target.result;
        const salesByProductVariant = convertCsvToArray(text);
        await uploadSalesByProductVariant(salesByProductVariant);
      };

      fileReader.readAsText(file);
    }
  };

  return (
    <Page title="Upload Sales Report">
      <Container maxWidth="sm">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Upload sales report for {storeName}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <FormLabel
            htmlFor="file-upload"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              fontSize: '18px',

              ':hover': {
                cursor: 'pointer',
              },
            }}
          >
            <Iconify icon="eva:upload-fill" />
            Click to upload file
          </FormLabel>

          <Input id="file-upload" type="file" onChange={handleChange} name="filename" sx={{ display: 'none' }} />

          {file && (
            <Typography variant="button" sx={{ textTransform: 'none' }}>
              {file.name}
            </Typography>
          )}

          <Button variant="contained" onClick={handleUpload}>
            Upload report for {storeName}
          </Button>

          {success.success && (
            <>
              <Alert color="success">{success.message}</Alert>
            </>
          )}
          {error.error && (
            <>
              <Alert color="error">{error.message}</Alert>
            </>
          )}
        </Box>
      </Container>
    </Page>
  );
}
