import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { IconButton } from '@mui/material';
import Iconify from '../Iconify';
import { useAuthContext } from '../../hooks/auth';
import { useHostContext } from '../../hooks/host';

export default function DeleteStore({ name, id, refetch, setIsDeleted }) {
  const [open, setOpen] = React.useState(false);

  const { accessToken } = useAuthContext();
  const { HOST } = useHostContext();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    try {
      await axios.delete(`${HOST}/store/${id}`, {
        headers: {
          'x-access-token': accessToken,
        },
      });
      setIsDeleted(true);
      handleClose();
      refetch({});
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <IconButton size="medium" color="error" onClick={handleClickOpen}>
        <Iconify icon="eva:trash-2-fill" />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`${name}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this store?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={async () => {
              await handleConfirm();
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
