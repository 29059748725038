import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { StoreContext } from './storeContext';
import { useHostContext } from '../host';
import { useAuthContext } from '../auth';

export const StoreProvider = ({ children }) => {
  const { Provider } = StoreContext;

  const { HOST } = useHostContext();
  const { accessToken } = useAuthContext();

  const [storeId, setStoreId] = useState(localStorage.getItem('storeId') || 1);

  useEffect(() => {
    if (storeId) {
      localStorage.setItem('storeId', storeId);
    }
  }, [storeId]);

  const data = {
    storeId,
    setStoreId,
    getAllStores: async () => {
      const stores = await axios({
        method: 'GET',
        url: `${HOST}/store`,
        headers: {
          'x-access-token': accessToken,
        }
      });

      return stores?.data;
    }
  };

  return <Provider value={data}>{children}</Provider>;
};
