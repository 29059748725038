import { Card, Box, CardHeader, Grid, List, ListItem, Typography, keyframes } from '@mui/material';
import { fadeIn } from 'react-animations';

import useFetch from '../../hooks/useFetch';
import compareHistoricalDifference from '../../utils/compareHistoricalDifference';
import formatNumberAsCurrency from '../../utils/formatNumberAsCurrency';
import Label from '../Label';
import OrderSummarySkeleton from '../OrderSummarySkeleton';

const DashboardOrderSummary = ({ dateFrom, dateTo }) => {
  const { data, loading } = useFetch({
    path: '/dashboard/order-summary',
    historical: true,
    dateFrom,
    dateTo,
  });

  const fadeInAnimation = keyframes`${fadeIn}`;

  const getLabelColor = (value) => {
    if (value === 0) {
      return 'info';
    }

    if (value > 0) {
      return 'success';
    }

    if (value < 0) {
      return 'error';
    }
  };

  return loading ? (
    <LoadingState />
  ) : (
    <Grid item xs={12} md={6} lg={6}>
      <Card sx={{ animation: `1s ${fadeInAnimation}` }}>
        <CardHeader title={'Order summary'} subheader={''} />
        <List
          variant="outlined"
          sx={{
            p: 4,
            '& .MuiListItem-root': {
              py: 2,
            },
          }}
        >
          <ListItem divider sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Average Order Value</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'no-wrap', gap: 2 }}>
              <Typography fontWeight={700}>{formatNumberAsCurrency(data.averageOrderValue)}</Typography>
              <Label color={getLabelColor(data.historical.averageOrderValue)}>
                {compareHistoricalDifference(data.historical.averageOrderValue)}
              </Label>
            </Box>
          </ListItem>
          <ListItem divider sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Ad Spend Per Order</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'no-wrap', gap: 2 }}>
              <Typography fontWeight={700}>{formatNumberAsCurrency(data.adSpendPerOrder)}</Typography>
              <Label color={getLabelColor(data.historical.adSpendPerOrder)}>
                {compareHistoricalDifference(data.historical.adSpendPerOrder)}
              </Label>
            </Box>
          </ListItem>
          <ListItem divider sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Average Order Profit</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'no-wrap', gap: 2 }}>
              <Typography fontWeight={700}>{formatNumberAsCurrency(data.averageOrderProfit)}</Typography>
              <Label color={getLabelColor(data.historical.averageOrderProfit)}>
                {compareHistoricalDifference(data.historical.averageOrderProfit)}
              </Label>
            </Box>
          </ListItem>
          <ListItem divider sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Shipping Charged</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'no-wrap', gap: 2 }}>
              <Typography fontWeight={700}>{formatNumberAsCurrency(data.shipping)}</Typography>
              <Label color={getLabelColor(data.historical.shipping)}>
                {compareHistoricalDifference(data.historical.shipping)}
              </Label>
            </Box>
          </ListItem>
          <ListItem divider sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Total Taxes</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'no-wrap', gap: 2 }}>
              <Typography fontWeight={700}>{formatNumberAsCurrency(data.taxes)}</Typography>
              <Label color={getLabelColor(data.historical.taxes)}>
                {compareHistoricalDifference(data.historical.taxes)}
              </Label>
            </Box>
          </ListItem>
          <ListItem divider sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Total Tips</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'no-wrap', gap: 2 }}>
              <Typography fontWeight={700}>{formatNumberAsCurrency(data.tips)}</Typography>
              <Label color={getLabelColor(data.historical.tips)}>
                {compareHistoricalDifference(data.historical.tips)}
              </Label>
            </Box>
          </ListItem>
          <ListItem divider sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Gift Card Sales</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'no-wrap', gap: 2 }}>
              <Typography fontWeight={700}>{formatNumberAsCurrency(data.giftCardSales)}</Typography>
              <Label color={getLabelColor(data.historical.giftCardSales)}>
                {compareHistoricalDifference(data.historical.giftCardSales)}
              </Label>
            </Box>
          </ListItem>
          <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Returns</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'no-wrap', gap: 2 }}>
              <Typography fontWeight={700}>{formatNumberAsCurrency(Math.abs(data.returns))}</Typography>
              <Label color={getLabelColor(data.historical.returns)}>
                {compareHistoricalDifference(data.historical.returns)}
              </Label>
            </Box>
          </ListItem>
        </List>
      </Card>
    </Grid>
  );
};

const LoadingState = () => {
  return (
    <Grid item xs={12} md={6} lg={6}>
      <OrderSummarySkeleton />
    </Grid>
  );
};

export default DashboardOrderSummary;
