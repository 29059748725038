import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useJwt } from 'react-jwt';
import { AuthContext } from './authContext';
import { useHostContext } from '../host';

export const AuthProvider = ({ children }) => {
  const { Provider } = AuthContext;

  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'));
  const [isUserAuthed, setIsUserAuthed] = useState(!!accessToken);
  const [user, setUser] = useState(null);
  const { decodedToken } = useJwt(accessToken);

  const { HOST } = useHostContext();

  useEffect(() => {
    if (decodedToken) {
      setUser(decodedToken);
    }
  }, [decodedToken]);

  useEffect(() => {
    async function verify() {
      if (!accessToken) {
        setIsUserAuthed(false);
      } else {
        try {
          await axios.post(`${HOST}/auth/verify`, null, {
            headers: {
              'x-access-token': accessToken,
            },
          });

          setIsUserAuthed(true);
        } catch (err) {
          console.log(err);
          setIsUserAuthed(false);
        }
      }
    }
    verify();
  }, [accessToken]);

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
    }
  }, [accessToken]);

  const data = {
    user,
    accessToken,
    isUserAuthed,
    login: async (email, password) => {
      const loginResult = await axios.post(`${HOST}/auth/login`, {
        email,
        password,
      });

      setIsUserAuthed(true);
      setAccessToken(loginResult.data.token);
    },
    register: async (userPayload) => {
      const registerResult = await axios.post(`${HOST}/auth/register`, {
        ...userPayload,
      });

      console.log(registerResult);
    },
    sendResetLink: async (email) => {
      await axios.post(`${HOST}/auth/forgot-password`, {
        email,
      });
    },
    reset: async (token, email, password) => {
      await axios.post(`${HOST}/auth/reset-password`, {
        token,
        email,
        password,
      });
    },
    logout: async () => {
      localStorage.removeItem('accessToken', accessToken);
    },
  };

  return <Provider value={data}>{children}</Provider>;
};
