import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader, Box, Typography } from '@mui/material';
import { BaseOptionChart } from '../../../components/chart';
import formatNumberAsCurrency from '../../../utils/formatNumberAsCurrency';
import Label from '../../../components/Label';
import compareHistoricalDifference from '../../../utils/compareHistoricalDifference';

const CHART_HEIGHT = 420;
const LEGEND_HEIGHT = 80;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    display: 'flex',
    flexDirection: 'column',
    wrap: 'wrap',
    gap: 10,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

CostBreakdown.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  chartColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.array,
  color: PropTypes.object,
  total: PropTypes.number,
};

const Dot = ({ color }) => (
  <span
    style={{
      height: '10px',
      width: '10px',
      backgroundColor: color,
      borderRadius: '50%',
      display: 'inline-block',
    }}
  />
);

Dot.propTypes = {
  color: PropTypes.string,
};

export default function CostBreakdown({ title, subheader, total, chartColors, chartData, ...other }) {
  const theme = useTheme();

  const chartLabels = chartData.map((i) => `${i.label}`);

  const chartSeries = chartData.map((i) => i.value);

  const chartOptions = merge(BaseOptionChart(), {
    colors: chartColors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { show: false, floating: true, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => formatNumberAsCurrency(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              formatter: () => formatNumberAsCurrency(total),
            },
            value: {
              formatter: (value) => formatNumberAsCurrency(value),
            },
          },
        },
      },
    },
  });

  const getLabelColor = (value) => {
    if (value === 0) {
      return 'info';
    }

    if (value > 0) {
      return 'error';
    }

    if (value < 0) {
      return 'success';
    }
  };

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          flexWrap: 'wrap',
          py: 2,
        }}
      >
        <ChartWrapperStyle dir="ltr">
          <ReactApexChart type="donut" series={chartSeries} options={chartOptions} height={380} />
        </ChartWrapperStyle>
        <Box>
          {chartData.map((legend, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 1, mt: 1 }}>
              <Box sx={{ display: 'flex', flexWrap: 'no-wrap', alignItems: 'center', gap: 1 }}>
                <Dot color={chartColors[index]} />
                <Typography fontWeight="bold">{legend.label}</Typography>
              </Box>
              <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', gap: 1 }}>
                <Typography>{formatNumberAsCurrency(legend.value)}</Typography>
                <Label color={getLabelColor(legend.historical)}>
                  {compareHistoricalDifference(Math.round(legend.historical), false, true)}
                </Label>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Card>
  );
}
