import { IconButton, Tooltip } from '@mui/material';
import Iconify from '../components/Iconify';

export default (value) => {
  if (value === undefined || value === null) {
    return (
      <Tooltip title="Calculation for this metric is not possible!">
        <IconButton color="error" sx={{ py: 0, px: 0 }}>
          <Iconify icon="eva:flash-fill" sx={{ fontSize: '18px' }} />
        </IconButton>
      </Tooltip>
    );
  }

  return `${Math.abs(value)}%`;
};
