import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useStoreContext } from '../hooks/store';

export default function StoreSelect() {
  const { getAllStores, storeId, setStoreId } = useStoreContext();
  const [stores, setStores] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const open = Boolean(anchorEl);

  useEffect(() => {
    async function fetchStores() {
      const allStores = await getAllStores();
      setStores(allStores);
    }

    fetchStores();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stores.length > 0) {
      const selectedStore = stores.map((store) => store.id).indexOf(Number(storeId));
      setSelectedIndex(selectedStore);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores]);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setStoreId(stores[index].id);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return stores.length ? (
    <div>
      <List
        component="nav"
        aria-label="Device settings"
        sx={{ bgcolor: 'background.paper', borderRadius: '12px', padding: 0 }}
      >
        <ListItem
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
        >
          <ListItemText primary={stores[selectedIndex]?.name} secondary={stores[selectedIndex]?.url} />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {stores.map((store, index) => (
          <MenuItem
            key={store.id}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {store.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  ) : (
    <></>
  );
}
