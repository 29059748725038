import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, keyframes, Typography } from '@mui/material';
import { fadeIn } from 'react-animations';
import Iconify from '../../../components/Iconify';
import Label from '../../../components/Label';
import compareHistoricalDifference from '../../../utils/compareHistoricalDifference';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  historical: PropTypes.number,
  title: PropTypes.string.isRequired,
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({ title, total, icon, historical, color = 'primary', sx, ...other }) {
  const fadeInAnimation = keyframes`${fadeIn}`;

  return (
    <Card
      sx={{
        animation: `1s ${fadeInAnimation}`,
        py: 5,
        boxShadow: 0,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      <IconWrapperStyle
        sx={{
          color: (theme) => theme.palette[color].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
              theme.palette[color].dark,
              0.24
            )} 100%)`,
        }}
      >
        <Iconify icon={icon} width={24} height={24} />
      </IconWrapperStyle>

      <Typography variant="h3">{total}</Typography>

      <Label sx={{ mb: 1 }}>{compareHistoricalDifference(historical, true)}</Label>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
    </Card>
  );
}
