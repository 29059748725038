import { filter } from 'lodash';

import {
  Card,
  Container,
  keyframes,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Box,
  Button,
} from '@mui/material';
import { useState, useMemo } from 'react';

import { fadeIn } from 'react-animations';
import moment from 'moment';
import axios from 'axios';
import Page from '../components/Page';
import useFetch from '../hooks/useFetch';
import { SearchToolbar, UserListHead } from '../sections/@dashboard/user';
import SearchNotFound from '../components/SearchNotFound';
import Label from '../components/Label';
import LoadingSkeleton from '../components/LoadingSkeleton';
import { useAuthContext } from '../hooks/auth';
import { useHostContext } from '../hooks/host';

const TABLE_HEAD = [
  { id: 'firstName', label: 'First name', align: 'left' },
  { id: 'lastName', label: 'Last name', align: 'left' },
  { id: 'role', label: 'Role', align: 'left' },
  { id: 'email', label: 'Email', align: 'left' },
  { id: 'updatedAt', label: 'Last updated', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'actions', label: '', align: 'left' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.firstName.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.role.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.lastName.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Team() {
  const [filterName, setFilterName] = useState('');
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('startDate');
  const { accessToken } = useAuthContext();
  const { HOST } = useHostContext();

  const {
    data: users,
    loading: usersLoading,
    refetch: refetchUsers,
  } = useFetch({
    path: '/user/get',
  });

  const handleApproveUser = async (userId) => {
    try {
      await axios.post(
        `${HOST}/user/approve`,
        {
          userId,
        },
        {
          headers: {
            'x-access-token': accessToken,
          },
        }
      );

      refetchUsers({});
    } catch (err) {
      console.log(err);
    }
  };

  const handleSuspendUser = async (userId) => {
    try {
      await axios.post(
        `${HOST}/user/suspend`,
        {
          userId,
        },
        {
          headers: {
            'x-access-token': accessToken,
          },
        }
      );

      refetchUsers({});
    } catch (err) {
      console.log(err);
    }
  };

  const statusMapper = {
    PENDING: 'Pending',
    APPROVED: 'Approved',
    DELETED: 'Suspended',
  };

  const filteredUsers = useMemo(() => {
    if (!usersLoading) {
      return applySortFilter(users, getComparator(order, orderBy), filterName).map((user) => ({
        ...user,
        status: statusMapper[user.status],
      }));
    }

    return [];
  }, [usersLoading, filterName, users, order, orderBy]);

  const fadeInAnimation = keyframes`${fadeIn}`;

  const emptyRows = 0;

  const isUserNotFound = filteredUsers.length === 0;

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getLabelColor = (status) => {
    if (status === 'Pending') {
      return 'warning';
    }

    if (status === 'Approved') {
      return 'success';
    }

    if (status === 'Suspended') {
      return 'error';
    }

    return null;
  };

  return (
    <Page title="Manage Team">
      <Container maxWidth="xl">
        <Box sx={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'space-between' }}>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Manage Team
          </Typography>
        </Box>

        {usersLoading && <LoadingSkeleton />}
        {!usersLoading && (
          <Card
            sx={{
              animation: `1s ${fadeInAnimation}`,
            }}
          >
            <SearchToolbar filterName={filterName} onFilterName={handleFilterByName} title="" />
            <TableContainer sx={{ maxHeight: 'calc(100vh - 350px)' }}>
              <Table stickyHeader>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={users.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsers.map((user, userIndex) => (
                    <TableRow key={userIndex}>
                      <TableCell align="left">{user.firstName}</TableCell>
                      <TableCell align="left">{user.lastName}</TableCell>
                      <TableCell align="left">{user.role}</TableCell>
                      <TableCell align="left">{user.email}</TableCell>
                      <TableCell align="left">{moment(user.updatedAt).format('DD-MM-YYYY HH:mm')}</TableCell>
                      <TableCell align="left">
                        <Label color={getLabelColor(user.status)}>{user.status}</Label>
                      </TableCell>
                      <TableCell align="left">
                        {user.status === 'Pending' && (
                          <Button size="small" onClick={() => handleApproveUser(user.id)} variant="contained">
                            Approve
                          </Button>
                        )}

                        {user.status === 'Approved' && (
                          <Button
                            size="small"
                            color="error"
                            onClick={() => handleSuspendUser(user.id)}
                            variant="contained"
                          >
                            Suspend
                          </Button>
                        )}

                        {user.status === 'Suspended' && (
                          <Button
                            size="small"
                            sx={{ color: 'white' }}
                            color="warning"
                            onClick={() => handleApproveUser(user.id)}
                            variant="contained"
                          >
                            Recover
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={13} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={13} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[{ value: filteredUsers.length, label: 'Show all' }]}
              component="div"
              onPageChange={() => {}}
              count={filteredUsers.length}
              rowsPerPage={filteredUsers.length}
              page={0}
            />
          </Card>
        )}
      </Container>
    </Page>
  );
}
