// ----------------------------------------------------------------------

const account = {
  displayName: 'Nate',
  email: 'info@wrapango.com',
  photoURL: '/static/mock-images/avatars/avatar_default.jpg',
  password: 'admin',
  role: 'Staff',
};

export default account;
