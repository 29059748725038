import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import {
  FormControl,
  TextField,
  useTheme,
  Alert,
} from '@mui/material';

import Iconify from '../Iconify';
import Label from '../Label';
import { useAuthContext } from '../../hooks/auth';
import { useHostContext } from '../../hooks/host';

export default function CreateStore({ refetch, setIsCreated }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);

  const { accessToken } = useAuthContext();
  const { HOST } = useHostContext();

  const initialState = {
    name: '',
    url: '',
    googleCustomerId: ''
  };

  const [state, setState] = useState(initialState);

  const resetState = () => {
    setState(initialState);
    setError(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetState();
  };

  const handleChangeName = (event) => {
    setState({
      ...state,
      name: event.target.value,
    });
  };

  const handleChangeUrl = (event) => {
    setState({
      ...state,
      url: event.target.value,
    });
  };

  const handleChangeGoogleCustomerId = (event) => {
    setState({
      ...state,
      googleCustomerId: event.target.value,
    });
  };
  
  const handleChangeGoogleCustomerCurrency = (event) => {
    setState({
      ...state,
      googleCustomerCurrency: event.target.value,
    });
  };

  const validate = () => {
    const { name, url, googleCustomerId } = state;

    console.log(state);
    if (!name || !url || !googleCustomerId) {
      return false;
    }

    return true;
  };

  const handleConfirm = async () => {
    const isValid = validate();

    if (isValid) {
      try {
        await axios({
          method: 'post',
          url: `${HOST}/store`,
          data: {
            ...state,
          },
          headers: {
            'x-access-token': accessToken,
          },
        });

        setError(false);
        setIsCreated(true);
        handleClose();
        refetch({});
      } catch (err) {
        console.log(err);
      }
    }

    setError(true);
  };

  return (
    <>
      <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} size="large" onClick={handleClickOpen}>
        Create store
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={'md'}
        aria-labelledby="edit-dialog-title"
        aria-describedby="edit-dialog-description"
      >
        <DialogTitle id="edit-dialog-title" sx={{ display: 'flex', flexWrap: 'nowrap', gap: 2, alignItems: 'center' }}>
          <Iconify icon="eva:edit-fill" />
          {state.name}
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <DialogContentText>
            To create a store, fill out the necessary fields and click "Save". Alternatively, you can cancel via
            the "Cancel" button or by clicking anywhere outside of this dialog.
          </DialogContentText>
          <FormControl sx={{ mt: 2 }}>
            <TextField
              defaultValue={state.name}
              label={'Name'}
              id="name"
              aria-describedby="store-name"
              onChange={handleChangeName}
              helperText="Required field."
              required
            />
          </FormControl>
          <FormControl sx={{ mt: 2 }}>
            <TextField
              defaultValue={state.url}
              label={'Url'}
              id="url"
              aria-describedby="store-url"
              onChange={handleChangeUrl}
              helperText="Required field."
              required
            />
          </FormControl>
          <FormControl sx={{ mt: 2 }}>
            <TextField
              defaultValue={state.googleCustomerId}
              label={'Google Customer Id'}
              id="googleCustomerId"
              aria-describedby="store-google-customer-id"
              onChange={handleChangeGoogleCustomerId}
              helperText="Required field."
              required
            />
          </FormControl>
          <FormControl sx={{ mt: 2 }}>
            <TextField
              defaultValue={state.googleCustomerCurrency}
              label={'Google Customer Currency'}
              id="googleCustomerCurrency"
              aria-describedby="store-google-customer-currency"
              onChange={handleChangeGoogleCustomerCurrency}
              helperText="Required field."
              required
            />
          </FormControl>
          
          {error && <Alert severity="error">Please make sure you fill out the required fields marked with *</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={async () => {
              await handleConfirm();
            }}
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
