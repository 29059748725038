import { filter } from 'lodash';
import { useMemo, useState } from 'react';

import {
  Box,
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  keyframes,
  FormControl,
  InputLabel,
  Select,
  ListSubheader,
  MenuItem,
  Alert,
  AlertTitle,
} from '@mui/material';

import { fadeIn } from 'react-animations';
import { useAuthContext } from '../hooks/auth';
import { useHostContext } from '../hooks/host';

import Page from '../components/Page';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, SearchToolbar } from '../sections/@dashboard/user';

import useProductProfitabilityData from '../utils/useProductProfitabilityData';

import formatSalesAndProfitReport from '../utils/formatSalesAndProfitReport';
import SalesAndProfitByProductSkeleton from '../components/SalesAndProfitByProductSkeleton';

const TABLE_HEAD = [
  { id: 'productTitle', label: 'Product title', align: 'left', sticky: true },
  { id: 'profit', label: 'REAL PROFIT', align: 'center' },
  { id: 'profitMargin', label: 'Profit margin', align: 'center' },
  { id: 'grossProfit', label: 'Gross profit', align: 'center' },
  { id: 'totalSales', label: 'Total sales', align: 'center' },
  { id: 'cogs', label: 'COGS', align: 'center' },
  { id: 'marketing', label: 'Marketing', align: 'center' },
  { id: 'price', label: 'Price', align: 'center' },
  { id: 'netQuantity', label: 'Quantity', align: 'center' },
  { id: 'roas', label: 'ROAS', align: 'center' },
  { id: 'cogsMargin', label: 'COGS margin', align: 'center' },
  { id: 'acos', label: 'ACOS', align: 'center' },
  { id: 'grossSales', label: 'Gross sales', align: 'center' },
  { id: 'discounts', label: 'Discounts', align: 'center' },
  { id: 'returns', label: 'Returns', align: 'center' },
  { id: 'tax', label: 'Tax', align: 'center' },
  { id: 'roi', label: 'ROI', align: 'center' },
  { id: 'priority', label: 'Priority', align: 'center' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_product) => _product.productTitle.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function SalesAndProfitByProduct() {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('profit');
  const [filterName, setFilterName] = useState('');

  const { accessToken } = useAuthContext();
  const { HOST } = useHostContext();

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const [dateRange, setDateRange] = useState({
    dateFrom: null,
    dateTo: null,
  });

  const { saleReports, activeSaleReport, productProfitability, loading } = useProductProfitabilityData({
    ...dateRange,
    HOST,
    accessToken,
  });

  const hasData = Object.values(saleReports).length > 0;

  const filteredProducts = useMemo(() => {
    if (!loading && hasData) {
      return applySortFilter(productProfitability.products, getComparator(order, orderBy), filterName);
    }

    return [];
  }, [loading, filterName, productProfitability, order, orderBy, hasData]);

  const emptyRows = 0;

  const isProductNotFound = filteredProducts.length === 0;

  const handleChangeReportYearly = (salesReportId) => {
    const report = saleReports.yearlyReports.find((report) => report.id === salesReportId);

    const { dateFrom, dateTo } = report;

    setDateRange({ dateFrom, dateTo });
  };

  const handleChangeReportMonthly = (salesReportId) => {
    const report = saleReports.monthlyReports.find(({ reports }) =>
      reports.find((report) => report.id === salesReportId)
    );

    const saleReport = report.reports.find((report) => report.id === salesReportId);

    const { dateFrom, dateTo } = saleReport;

    setDateRange({ dateFrom, dateTo });
  };

  const handleChangeReportWeekly = (salesReportId) => {
    const report = saleReports.weeklyReports.find(({ reports }) =>
      reports.find((report) => report.id === salesReportId)
    );

    const saleReport = report.reports.find((report) => report.id === salesReportId);

    const { dateFrom, dateTo } = saleReport;

    setDateRange({ dateFrom, dateTo });
  };

  const fadeInAnimation = keyframes`${fadeIn}`;
  return (
    <Page title="Product Profitability Tracker">
      {loading && <SalesAndProfitByProductSkeleton />}
      {!loading && hasData && (
        <>
          <Stack direction="column" alignItems="left" justifyContent="space-between" mb={5}>
            <Box sx={{ mt: 0 }}>
              {loading === false && Object.keys(saleReports).length !== 0 && (
                <>
                  <Box sx={{ display: 'flex', gap: 2, flexWrap: 'nowrap' }}>
                    {saleReports.weeklyReports.length ? (
                      <FormControl sx={{ minWidth: '250px' }}>
                        <InputLabel htmlFor="grouped-select">Choose report by week</InputLabel>
                        <Select
                          defaultValue=""
                          id="grouped-select"
                          value={activeSaleReport.id}
                          label="Choose report by week"
                          onChange={(e) => handleChangeReportWeekly(e.target.value)}
                        >
                          {saleReports.weeklyReports.map(({ year, month, reports }) => [
                            <ListSubheader key={`${month} ${year}`}>{`${month} ${year}`}</ListSubheader>,
                            ...[
                              reports.map((report) => (
                                <MenuItem key={report.id} value={report.id}>
                                  {report.name}
                                </MenuItem>
                              )),
                            ],
                          ])}
                        </Select>
                      </FormControl>
                    ) : null}
                    {saleReports.monthlyReports.length ? (
                      <FormControl sx={{ minWidth: '250px' }}>
                        <InputLabel htmlFor="grouped-select-monthly">Choose report by month</InputLabel>
                        <Select
                          defaultValue=""
                          id="grouped-select-monthly"
                          value={activeSaleReport.id}
                          label="Choose report by month"
                          onChange={(e) => handleChangeReportMonthly(e.target.value)}
                        >
                          {saleReports.monthlyReports.map(({ year, reports }) => [
                            <ListSubheader key="year">{year}</ListSubheader>,
                            ...[
                              reports.map((report) => (
                                <MenuItem key={report.id} value={report.id}>
                                  {report.name}
                                </MenuItem>
                              )),
                            ],
                          ])}
                        </Select>
                      </FormControl>
                    ) : null}
                    {saleReports.yearlyReports.length ? (
                      <FormControl sx={{ minWidth: '250px' }}>
                        <InputLabel htmlFor="grouped-select-yearly">Choose report by year</InputLabel>
                        <Select
                          defaultValue=""
                          id="grouped-select-yearly"
                          value={activeSaleReport.id}
                          label="Choose report by year"
                          onChange={(e) => handleChangeReportYearly(e.target.value)}
                        >
                          {saleReports.yearlyReports.map((report) => (
                            <MenuItem key={report.id} value={report.id}>
                              {report.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : null}
                  </Box>
                </>
              )}
            </Box>
          </Stack>
          <Card
            sx={{
              animation: `1s ${fadeInAnimation}`,
            }}
          >
            <SearchToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              title="Product Profitability Tracker"
            />
            <TableContainer sx={{ maxHeight: 'calc(100vh - 385px)' }}>
              <Table
                stickyHeader
                sx={{
                  '& .MuiTableRow-root': {
                    '& .MuiTableCell-root': {
                      padding: '10px 14px',
                    },
                  },
                  '& .MuiTableRow-root:not(:first-of-type)': {
                    '& .MuiTableCell-root': {
                      backgroundColor: '#fff',
                    },
                  },
                  '& .MuiTableRow-root:hover': {
                    '& .MuiTableCell-root': {
                      backgroundColor: '#fafafa',
                    },
                  },
                  '& .Mui-selected': {
                    '& .MuiTableCell-root': {
                      backgroundColor: '#e3f2fd',
                    },
                  },
                  '& .Mui-selected:hover': {
                    '& .MuiTableCell-root': {
                      backgroundColor: '#bbdefb',
                    },
                  },
                }}
              >
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={productProfitability.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {formatSalesAndProfitReport(filteredProducts, productProfitability.totals)}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={13} />
                    </TableRow>
                  )}
                </TableBody>
                {isProductNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={TABLE_HEAD.length} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[{ value: filteredProducts.length, label: 'Show all' }]}
              component="div"
              count={filteredProducts.length}
              rowsPerPage={filteredProducts.length}
              onPageChange={() => {}}
              page={0}
            />
          </Card>
        </>
      )}
      {!loading && !hasData && (
        <Alert severity="info">
          <AlertTitle>Reports not found for store</AlertTitle>
          <p>Import reports by uploading .CSV files using the Upload Sales Report page.</p>
        </Alert>
      )}
    </Page>
  );
}
