import React from 'react';
import { HostContext } from './hostContext';

export const HostProvider = ({ children }) => {
  const { Provider } = HostContext;

  let HOST = '';

  if (process.env.NODE_ENV === 'development') {
    HOST = 'http://localhost:3001';
  }

  if (process.env.NODE_ENV === 'production') {
    HOST = 'https://realprofit.app/api';
  }

  return <Provider value={{ HOST }}>{children}</Provider>;
};
