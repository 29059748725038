import { useState } from 'react';

import { Grid, Container, Typography, Button } from '@mui/material';
import { DateRangePicker } from 'mui-daterange-picker';
import moment from 'moment';
import Page from '../components/Page';
import Iconify from '../components/Iconify';

import { getDefaultRanges } from '../utils/getDefaultDateRanges';
import getDateRangeLabel from '../utils/getDateRangeLabel';
import DashboardMetrics from '../components/dasboard/DasboardMetrics';
import DashboardCostBreakdown from '../components/dasboard/DashboardCostBreakdown';
import DashboardOrderSummary from '../components/dasboard/DashboardOrderSummary';

export default function DashboardApp() {
  const [open, setOpen] = useState(false);

  const toggle = () => setOpen(!open);

  const [dateRange, setDateRange] = useState({
    dateFrom: moment().add(-7, 'days').format('YYYY-MM-DD'),
    dateTo: moment().add(-1, 'days').format('YYYY-MM-DD'),
  });

  const handleChangeRange = (range) => {
    const dateFrom = moment(range.startDate).format('YYYY-MM-DD');
    const dateTo = moment(range.endDate).format('YYYY-MM-DD');

    setDateRange({ dateFrom, dateTo });
    setOpen(false);
  };

  const dateRangeLabel = getDateRangeLabel({ dateFrom: dateRange.dateFrom, dateTo: dateRange.dateTo });

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Button
          size="large"
          variant="contained"
          onClick={() => setOpen(true)}
          sx={{ mb: 5 }}
          startIcon={<Iconify icon="eva:calendar-fill" />}
        >
          {dateRangeLabel}
        </Button>
        <DateRangePicker
          open={open}
          toggle={toggle}
          onChange={(range) => handleChangeRange(range)}
          definedRanges={getDefaultRanges()}
          wrapperClassName="wrapper"
        />

        <Grid container spacing={4}>
          <DashboardMetrics {...dateRange} />
          <DashboardCostBreakdown {...dateRange} />
          <DashboardOrderSummary {...dateRange} />
          {/* 
      
          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Website Visits"
              subheader="(+43%) than last year"
              chartLabels={[
                '01/01/2003',
                '02/01/2003',
                '03/01/2003',
                '04/01/2003',
                '05/01/2003',
                '06/01/2003',
                '07/01/2003',
                '08/01/2003',
                '09/01/2003',
                '10/01/2003',
                '11/01/2003',
              ]}
              chartData={[
                {
                  name: 'Team A',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'Team B',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: 'Team C',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} height={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} height={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} height={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} height={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
